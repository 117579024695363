import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import PrivateRoute from "./components/PrivateRoute";
import HomePage from "./components/HomePage/HomePage";
import LoginPage from "./components/LoginPage/LoginPage";
// import PasswordEmailPage from './components/PasswordEmailPage/PasswordEmailPage';
// import PasswordResetPage from './components/PasswordResetPage/PasswordResetPage';
import Categories from "./components/Categories/Categories";
import PhotoUsers from "./components/PhotoUsers/PhotoUsers";
import User from "./components/PhotoUsers/User/User";
import Header from "./components/Header/Header";
import Back from "./components/Back/Back";
import Lightbox from "./components/PhotoUsers/User/Lightbox/Lightbox";
import LightboxDetail from "./components/PhotoUsers/User/Lightbox/LightboxDetail/LightboxDetail";
import LightboxSharelink from "./components/PhotoUsers/User/Lightbox/LightboxSharelink/LightboxSharelink";
import { userService } from "./services/userServices";
import { defaultAuthorisationDetails } from "./services/user";

function App() {
  const [catName, setCatName] = useState("");
  useEffect(() => {
    localStorage.setItem("userName", defaultAuthorisationDetails.email);
    userService.login(defaultAuthorisationDetails.email, defaultAuthorisationDetails.password);
  }, []);
  const authed = userService.isLoggedin();

  let url = useLocation();

  if (!authed) return null;

  return (
    <div className="App">
      <div className={`container-fluid`}>
        {(url.pathname !== "/login" && authed && <Header />) || (url.pathname.includes("lightbox/") && <Header />)}
        <Back />
        <Switch>
          <PrivateRoute authed={authed} exact path="/" component={HomePage} />
          <Route path="/login" component={LoginPage} />
          {/* <Route path='/forgotten-password' component={PasswordEmailPage} />
              <Route path='/reset-password/:token' component={PasswordResetPage} /> */}

          <PrivateRoute
            exact
            authed={authed}
            path="/categories"
            component={() => <Categories passCatName={setCatName} />}
          />
          <PrivateRoute
            exact
            authed={authed}
            path="/categories/:id/"
            component={() => <PhotoUsers catName={catName} />}
          />
          <PrivateRoute exact authed={authed} path="/categories/:category_id/:photographer_id" component={User} />
          <PrivateRoute exact authed={authed} path="/lightboxes" component={Lightbox} />
          <PrivateRoute exact authed={authed} path="/lightboxes/:lightboxes_id" component={LightboxDetail} />
          <Route path="/lightbox/:sharelink" component={LightboxSharelink} />
        </Switch>
      </div>
    </div>
  );
}

export default App;
