import React, { useState } from "react";

import { useForm } from "react-hook-form";

//scss
import classes from "./GetInTouch.module.scss";

import { getFileName } from "../../../helpers/FileCaching";
const Images = require.context("./images/GetInTouch", true, /.*\.(gif|png|jpe?g|svg)$/i);

const GetInTouch = ({ content }) => {
  const { register, handleSubmit, errors, reset } = useForm();

  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);

  const onSubmit = async inputForms => {
    const formData = new FormData();
    formData.append("name", inputForms.name);
    formData.append("email", inputForms.email);
    formData.append("phone", inputForms.phone);
    formData.append("message", inputForms.message);

    let response = await fetch("https://admin.squint-box.com/squintbox-api/email/get-in-touch", {
      method: "post",
      body: formData
    });

    const res = await response.json();

    if (res.status === "success") {
      setIsSuccessfullySubmitted(res.message);
      window.dataLayer.push({
        event: "get_in_touch",
        page_category: "Homepage",
        page_name: "Site - Home",
        date_time: new Date().toLocaleString()
      });
    }

    reset();
  };
  return (
    <div className="marketing">
      <div className={classes.getInTouch}>
        <div className={`${classes.getInTouch__wrap}`}>
          <div className={`${classes.cta}`}>
            <h1 className={`${classes.cta__headline}`}>{content.title}</h1>
            {/* <h2 className={`${classes.cta__subtitle}`}>We'd be delighted to hear from you.</h2> */}
            <p className={`${classes.cta__body}`} dangerouslySetInnerHTML={{ __html: content.body }}></p>
            <div className={`${classes.cta__social_icon_row}`}>
              <a href="mailto:info@squint-box.com" title="email info@squintbox.com">
                <img
                  src="/mail_white.png"
                  className={`${classes.cta__social_icon_row__social_icon__mail}`}
                  alt="Email"
                />
              </a>
              <a
                href="https://www.instagram.com/squint_box/"
                target="_blank"
                rel="noopener noreferrer"
                title="Visit our instagram">
                <img
                  src="/insta_white.png"
                  className={`${classes.cta__social_icon_row__social_icon__instagram}`}
                  alt="Instagram"
                />
              </a>
            </div>
          </div>
          <form
            className={classes.form}
            onSubmit={handleSubmit(onSubmit)}
            id="get-in-touch"
            encType="multipart/form-data"
            method="post">
            <div className={`input-group ${classes.form__input_group}`}>
              <div className={`input-group-prepend ${classes.form__labelName}`}>
                <span id="name">name</span>
              </div>
              <input
                type="text"
                className={`form-control ${classes.form__input}`}
                aria-label="name"
                aria-describedby="name"
                name="name"
                ref={register({ required: "This field is required" })}
              />
            </div>
            {errors.name && <p className={classes.textError}>{errors.name.message}</p>}
            <div className={`input-group ${classes.form__input_group}`}>
              <div className={`input-group-prepend ${classes.form__labelName}`}>
                <span id="email">e-mail</span>
              </div>
              <input
                type="email"
                className={`form-control ${classes.form__input}`}
                aria-label="email"
                aria-describedby="email"
                name="email"
                ref={register({
                  required: "This field is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address"
                  }
                })}
              />
            </div>
            {errors.email && <p className={classes.textError}>{errors.email.message}</p>}
            <div className={`input-group ${classes.form__input_group}`}>
              <div className={`input-group-prepend ${classes.form__labelName}`}>
                <span id="phone">Phone</span>
              </div>
              <input
                type="text"
                className={`form-control ${classes.form__input}`}
                aria-label="phone"
                aria-describedby="phone"
                name="phone"
                ref={register}
              />
            </div>
            <div className={`${classes.form__input_group}`}>
              <span id="message" className={`d-block ${classes.form__labelName_message} `}>
                Message
              </span>
              <textarea
                className={`${classes.form__textarea}`}
                id="message"
                aria-label="message"
                aria-describedby="message"
                name="message"
                ref={register}></textarea>
            </div>
            {isSuccessfullySubmitted && <div className={classes.successForm}>{isSuccessfullySubmitted}</div>}
            <div className="form-group d-flex justify-content-end">
              <button type="submit" className={` ${classes.form__submitBtn}  `}>
                <span className={classes.form__submitBtn__text}>Submit</span>
                <span
                  className={classes.form__submitBtn_icon}
                  style={{ backgroundImage: "url(" + getFileName(Images, "submit.png") + ")" }}></span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
