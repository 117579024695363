import React, { useEffect, useRef, useState } from "react";
import classes from "./HomePage.module.scss";
import SBTracking from "../../services/tracking";
import Hero from "../Hero/Hero";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Data from "./homepageContent.json"; // TODO: Create this file
import ThinkInsideTheBox from "./ThinkInsideTheBox/ThinkInsideTheBox";
import GetInTouch from "./GetInTouch/GetInTouch";

import { News } from "./ArtistShowcase/ArtistShowcase";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

const HomePage = () => {
  let [activeSrc, setActiveSrc] = useState(false);

  const aboutRef = useRef(null);

  useEffect(() => {
    let pageViewData = {
      page_category: "Welcome",
      page_name: "App - Welcome"
    };
    SBTracking.pageView(pageViewData);
  }, []);

  const location = useLocation();

  useEffect(() => {
    const startAboutVideoScrollTrigger = ScrollTrigger.create({
      trigger: aboutRef.current,
      start: "top 75%",
      end: "bottom top",
      onEnter: () => {
        setActiveSrc(true);
      },
      onEnterBack: () => {
        setActiveSrc(true);
      },
      onLeave: () => {
        setActiveSrc(false);
      },
      onLeaveBack: () => {
        setActiveSrc(false);
      }
    });
    return () => {
      startAboutVideoScrollTrigger.kill();
    };
  }, []);

  useEffect(() => {
    const state = location?.state;
    if (state && state.moveTo) {
      gsap.to(window, { scrollTo: { y: `#${state.moveTo}`, offsetY: 150 }, duration: 1 });
    } else {
      gsap.to(window, { scrollTo: 0, duration: 1 });
    }
  }, [location]);

  return (
    <>
      <div style={{ minHeight: "100vh", display: "flex", alignItems: "center" }} className="section">
        <div className={`${classes.HomePage} `}>
          <Hero />
        </div>
      </div>
      <span id="about"></span>
      <div ref={aboutRef} style={{ minHeight: "100vh", display: "flex", alignItems: "center" }} className="section">
        <ThinkInsideTheBox content={Data.thinkInsideTheBox} sourceAdd={activeSrc} />
      </div>
      {Data.news && (
        <>
          <span id="news"></span>
          <div style={{ minHeight: "100vh" }} className="section">
            <News content={Data.news} />
          </div>
        </>
      )}
      <span id="contact"></span>
      <div style={{ minHeight: "100vh", display: "flex", alignItems: "center" }} className="section">
        <GetInTouch content={Data.getInTouch} />
      </div>
    </>
  );
};

export default HomePage;
