import React, { useState, useEffect } from "react";
import news from "./ArtistShowcase.module.scss";
import { Link } from "react-router-dom/cjs/react-router-dom";

/**
 *
 * @param content {{title?: string; artistName?: string; artistId?: number; description: string; link?: string; image: {src: string; categoryId?: number; categoryName?: string;}; }[]}
 */
export const News = ({ content }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 992) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={news.wrapper}>
      <div style={{ width: "90%", margin: "0 auto" }}>
        <h2 style={{ fontSize: "8vh" }}>NEWS</h2>
      </div>
      <Showcase>
        {content.slice(0, 3).map((artist, index) => {
          const { artistId, image, link } = artist;
          return (
            <>
              {!isMobile && (
                <div className={`${news.link} ${news.showcase} ${news.showcaseNoLink}`} key={index}>
                  <ShowcaseContent artist={artist} />
                </div>
              )}
              {isMobile && (
                <Link
                  className={`${news.link} ${news.showcaseLink} ${news.showcase}`}
                  to={{
                    pathname: artistId && image.categoryId ? `/categories/${image.categoryId}/${artistId}` : link,
                    state: { userType: "photographer" }
                  }}>
                  <ShowcaseContent artist={artist} />
                </Link>
              )}
            </>
          );
        })}
      </Showcase>
    </div>
  );
};

const ShowcaseContent = ({ artist }) => {
  const { title, description, artistName, artistId, image, link, linkText } = artist;
  return (
    <>
      <img className={`${news.image}`} src={image.src} alt={artistName} />
      <div className={`${news.cover}`} />
      <div className={`${news.artistShowcase} ${news.showcase} `}>
        {image.categoryName && <h4 className={`${news.artistShowcaseCategory}`}>{image.categoryName}</h4>}
        {(title || artistName) && <h2 className={`${news.artistShowcaseName}`}>{title ? title : artistName}</h2>}
        <p className={`${news.description}`} dangerouslySetInnerHTML={{ __html: description }} />
        <Link
          className={`${news.link}`}
          to={{
            pathname: artistId && image.categoryId ? `/categories/${image.categoryId}/${artistId}` : link,
            state: { userType: "photographer" }
          }}>
          <h2 className={`${news.artistShowcaseLink}`}>{linkText ?? "SEE MORE"}</h2>
        </Link>
      </div>
    </>
  );
};

const Showcase = ({ children }) => {
  const [child1, child2, child3] = children;

  return (
    <div className={`${news.template}`}>
      {child1 && <div className={news.a}>{child1}</div>}
      {child2 && <div className={news.b}>{child2}</div>}
      {child3 && <div className={news.c}>{child3}</div>}
    </div>
  );
};
